// import './bootstrap';

// import Alpine from 'alpinejs';

// window.Alpine = Alpine;

// Alpine.start();

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import './libs/slick.js'
import { Modal } from "bootstrap";
import globalJS from './global.js'
import intlTelInput from "intl-tel-input";

var loginModal = null;
var getInTouchModal = null;
let iti = null;
let itiFormBased = null;

let scheduleACallModal = null;

$(document).on('click', '.schedule-a-call-btn', function () {
    showScheduleACallModal();
});

function showScheduleACallModal() {
    scheduleACallModal.show();
}

$(document).on('click', '.get-in-touch-cta', function() {
    showGetInTouchModal();
});

$('.share-button.facebook').click(function() {
    let productUrl = $(this).data('url');

    var shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(productUrl);
    window.open(shareUrl, '_blank');
});

$('.share-button.linkedin').click(function() {

    let productUrl = $(this).data('url');

    var shareUrl = 'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(productUrl);
    window.open(shareUrl, '_blank');
});

$('.share-button.whatsapp').click(function() {
    let productUrl = $(this).data('url');
    let shareText = $(this).data('share-text');

    shareText += ' - ' + productUrl;
    var shareUrl = 'https://wa.me/?text=' + encodeURIComponent(shareText);
    window.open(shareUrl, '_blank');
});

$('.share-button.instagram').click(function() {
    alert('Instagram does not support direct URL sharing. You can share this product manually.');
});

$(document).on('click', '.js-share-btn', function() {

    let productUrl = $(this).data('url');
    let productName = $(this).data('name');
    let shareText = $(this).data('share-text');

    if (navigator.share) {
        navigator.share({
            title: productName,
            text: shareText,
            url: productUrl
        }).then(() => {
            console.log('Thanks for sharing!');
        }).catch(console.error);
    } else {
        // Fallback for browsers that do not support the Web Share API
        alert('Share this product: ' + productUrl);
    }
});

$(document).ready(function() {
    $(".page-loader-wrapper").hide();

    var $backgroundImage = $('.bg-image, .bg-image-top');
    $backgroundImage.each(function() {
        var $this = $(this),
            $bgImage = $this.data('bs-bg');
        $this.css('background-image', 'url('+$bgImage+')');
    });

    getInTouchModal =  new Modal($('#getInTouchModal'), {
        backdrop: 'static',
        keyboard: false
    });

    scheduleACallModal =  new Modal($('#schedule-a-call-modal'), {
        backdrop: 'static',
        keyboard: false
    });
});

function getIpInfo() {
    $.ajax({
        url: appUrl + '/ip/details',
        method: "GET",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'ip': requestIp
        },
        success: function (data) {
            // console.log(data);
            let responseData = data.response.data
            let ip = responseData.country_code ? responseData.country_code.toLowerCase() : null;

            let countryCodeFromIp = 'ae'
            if(ip) {
                countryCodeFromIp = ip;
            }

            // initIntl(countryCodeFromIp);
        },
        error: function (err) {
            let countryCodeFromIp = 'ae';
            // initIntl(countryCodeFromIp);
        }
    });
}

function initIntl(initialCountryCode, qSelector = '.countryCode') {
    const countryCodeInput = document.querySelector(qSelector);
    console.log('intl called', qSelector);
    if(countryCodeInput) {
        if(iti) {
            iti.destroy();
        }
        iti = intlTelInput(countryCodeInput, {
            initialCountry: initialCountryCode || 'ae',
            separateDialCode: true,
            preferredCountries: ['ae', 'sa', 'kw', 'qa', 'bh', 'om', 'in']
        });
    }

    const countryCodeFormBasedInput = document.querySelector(".countryCodeFormBased");
    if(countryCodeFormBasedInput) {
        if(itiFormBased) {
            itiFormBased.destroy();
        }
        itiFormBased = intlTelInput(countryCodeFormBasedInput, {
            initialCountry: initialCountryCode || 'ae',
            separateDialCode: true,
            preferredCountries: ['ae', 'sa', 'kw', 'qa', 'bh', 'om', 'in']
        });
    }
}

$(document).on('click', '.getInTouchBtn, .download-cat', function () {
    console.log('here 1');
    showGetInTouchModal();
});

function submitGetInTouch(type) {

    let getInTouchForm = null;

    if(type === 'modal') {
        getInTouchForm = $('#modal-get-in-touch-form');
    } else if(type === 'form') {
        getInTouchForm = $('#get-in-touch-form');
    } else if(type === 'expert-form') {
        getInTouchForm = $('#talk-to-expert-form');
    }

    if(!getInTouchForm) {
        return ;
    }

    let btn = getInTouchForm.find('.js-get-in-touch-submit');
    let url = getInTouchForm.attr('action');
    let source = getInTouchForm.data('source');

    if (!getInTouchForm.valid()) {
        return;
    }

    globalJS.gaSubmitEvent('GET_IN_TOUCH_INITIATED');
    globalJS.fbSubmitEvent('GET_IN_TOUCH_INITIATED');
    globalJS.startBtnLoader(btn);

    let countryCode = getInTouchForm.find('.iti__selected-dial-code').text();
    // console.log(countryCode);
    if (countryCode.charAt(0) === '+') {
        countryCode = countryCode.substr(1);
    }

    let form = $(getInTouchForm)[0];
    let formData = new FormData(form);
    formData.append('calling_code', countryCode);

    if(source) {
        formData.append('source', source);
    }

    if(utmSource) {
        formData.append('utm_source', utmSource);
    }

    if(referer) {
        formData.append('referer', referer);
    }

    console.log(formData);

    $.ajax({
        url: url,
        method: "POST",
        processData: false,
        contentType: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: formData,
        success: function (response) {
            globalJS.gaSubmitEvent('GET_IN_TOUCH_COMPLETED');
            globalJS.fbSubmitEvent('GET_IN_TOUCH_COMPLETED');
            globalJS.displayMessageToast('Thank you for registering with us, our team will reach out to you shortly', 'sucesss');
            globalJS.stopBtnLoader(btn);
            getInTouchModal.hide();
        },
        error: function (err) {
            // globalJS.displayMessageToast(err);
            globalJS.stopBtnLoader(btn);
        }
    });
}

$(document).on('click', '.js-get-in-touch-submit', function(e) {
    e.preventDefault();
    let type = $(this).data('type');
    submitGetInTouch(type);
});

function showGetInTouchModal() {
    globalJS.gaSubmitEvent('GET_IN_TOUCH_HIT');
    globalJS.fbSubmitEvent('GET_IN_TOUCH_HIT');
    getIpInfo();
    getInTouchModal.show();
}

function showLoginModal() {
    loginModal = new Modal($('#loginModal'), {
        backdrop: 'static',
        keyboard: false
    });
    loginModal.show();
}

$(document).on('click', '.js-login-btn', function () {
    $('.ltn__utilize-close').trigger('click');
    showLoginModal();
});

$(document).on('click', '.close', function () {
    if (loginModal) {
        loginModal.hide();
        loginModal.dispose();
    }
});

$(document).ready(function() {

    // showLoginModal();

    var $window = $(window),
    $body = $('body');

    /* --------------------------------------------------------
        2. Mobile Menu
    --------------------------------------------------------- */
    /* ---------------------------------
       Utilize Function
   ----------------------------------- */
    (function () {
        var $ltn__utilizeToggle = $('.ltn__utilize-toggle'),
            $ltn__utilize = $('.ltn__utilize'),
            $ltn__utilizeOverlay = $('.ltn__utilize-overlay'),
            $mobileMenuToggle = $('.mobile-menu-toggle');
        $ltn__utilizeToggle.on('click', function (e) {
            e.preventDefault();
            var $this = $(this),
                $target = $this.attr('href');
            $body.addClass('ltn__utilize-open');
            $($target).addClass('ltn__utilize-open');
            $ltn__utilizeOverlay.fadeIn();
            if ($this.parent().hasClass('mobile-menu-toggle')) {
                $this.addClass('close');
            }
        });
        $('.ltn__utilize-close, .ltn__utilize-overlay').on('click', function (e) {
            e.preventDefault();
            $body.removeClass('ltn__utilize-open');
            $ltn__utilize.removeClass('ltn__utilize-open');
            $ltn__utilizeOverlay.fadeOut();
            $mobileMenuToggle.find('a').removeClass('close');
        });
    })();

    const userButton = $('#user-btn');
    const submenu = $('.submenu');

    // Toggle the submenu on click
    userButton.on('click', function(event) {
        event.preventDefault(); // Prevent the link from navigating
        submenu.toggle();
    });

    // Close the submenu when clicking outside
    $(document).on('click', function(event) {
        if (submenu.is(':visible') && !userButton.is(event.target) && submenu.has(event.target).length === 0) {
            submenu.hide();
        }
    });

    initIntl('ae');
    initIntl('ae', '.git-calling-code');

    $('.property-card-slider').slick({
        autoplay: false,   // Auto-play the slider
        dots: false,       // Show pagination dots
        arrows: true,     // Show navigation arrows
        infinite: true,   // Loop the slider
        speed: 100,       // Transition speed in milliseconds
        slidesToShow: 1,  // Number of slides to show at a time
        slidesToScroll: 1, // Number of slides to scroll at a time
        prevArrow: '<a class="slick-prev"><img class="custom-arrow-slick custom-left" src="../images/icons/left-arrow-grey.png"></a>',
        nextArrow: '<a class="slick-next"><img class="custom-arrow-slick custom-right" src="../images/icons/right-arrow-grey.png"></i></a>',
    });

    $('.global-slider').slick({
        autoplay: false,   // Auto-play the slider
        dots: false,       // Show pagination dots
        arrows: false,     // Show navigation arrows
        infinite: true,   // Loop the slider
        speed: 100,       // Transition speed in milliseconds
        slidesToShow: 1,  // Number of slides to show at a time
        slidesToScroll: 1, // Number of slides to scroll at a time
        prevArrow: '<a class="slick-prev slick-arrow"><i class="fa fa-arrow-left" alt="Arrow Icon"></i></a>',
        nextArrow: '<a class="slick-next slick-arrow"><i class="fa fa-arrow-right" alt="Arrow Icon"></i></a>',
    });
});
